.navigation-main {
    height: 2.5rem;
    text-align: center;
    padding-top: .25rem;
}

.navigation-sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.navigation-logo {
    height: 1.5rem;
    border-radius: 1.5rem;
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: center;
    inline-size: 1.5rem;
    margin: auto;
}

.navigation-name {
    height: .75rem;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: .35rem;
}