.footer-main {
    height: 2.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    padding-top: .25rem;
}

.footer-credit-text {
    height: .75rem;
    font-family: 'Work Sans', sans-seriff;
    font-weight: 300;
    font-size: .25rem;
}

.sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}
