.template-editor-main button{
    font-size: .3rem;
    font-weight: 600;
    width: 1.8rem;
    height: .7rem;
    margin: auto 0 auto .4rem;
}

.template-editor-buttons div{
    text-align: right;
}

.template-editor-title{
    margin: .6rem 0;
}

.template-editor-title div{
    height: 1rem;
    font-size: .4rem;
}

.template-editor-title-field{
    width: 100%;
}

.template-editor-title label{
    font-size: .35rem;
    line-height: .6rem;
}

.template-editor-section-new{
    margin: .6rem 0;
}

.template-editor-button{
    font-size: .6rem;
    margin: .15rem;
    cursor: pointer;
}

.template-editor-saveIcon{
    color:#90be6d;
}

.template-editor-clearIcon{
    color:#f08080;
}

.template-editor-section-new-button{
    text-align: center;
    padding-top: .8rem;
    padding-bottom: .8rem;
    height: 2.65rem;
    font-size: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed gainsboro;
    border-radius: .5rem;
    color: gray;
    cursor: pointer;
}