.form{
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: relative;
}
.username-label{
    position: fixed;
    margin-left: -3.8rem;
    margin-top: -0.3rem;
    font-size: 0.35rem;
    font-family: 'Work Sans';
    z-index: 1;
    background-color: white;
    border-left: solid 0.1rem;
    border-right: solid 0.1rem;
    border-color: white;
}

.username{
    position: fixed;
    height: 1rem;
    width: 6rem;
    font-size: 0.4rem;
    font-family: 'Work Sans';
    border: solid 0.07rem;
    border-radius: 0.12rem;
    border-color: black;
}

.password-label{
    position: fixed;
    margin-left: -3.9rem;
    margin-top: 1.2rem;
    font-size: 0.35rem;
    font-family: 'Work Sans';
    z-index: 1;
    background-color: white;
    border-left: solid 0.1rem;
    border-right: solid 0.1rem;
    border-color: white;
}

.password{
    height: 1rem;
    width: 6rem;
    position: fixed;
    margin-top: 1.5rem;
    font-size: 0.4rem;
    font-family: 'Work Sans';
    border: solid 0.07rem;
    border-radius: 0.12rem;
    border-color: black;
}

.church-code-label{
    position: relative;
    margin-left: -3.25rem;
    margin-top: 2.7rem;
    font-size: 0.35rem;
    font-family: 'Work Sans';
    z-index: 1;
    background-color: white;
    border-left: solid 0.1rem;
    border-right: solid 0.1rem;
    border-color: white;
}

.church-code{
    height: 1rem;
    width: 3.8rem;
    position: fixed;
    margin-right: 2.2rem;
    margin-top: 3rem;
    font-size: 0.4rem;
    font-family: 'Work Sans';
    border: solid 0.07rem;
    border-radius: 0.12rem;
    border-color: black;
}

.login-button{
    position: fixed;
    margin-top: 3rem;
    margin-left: 4.2rem;
    height: 1rem;
    width: 1.8rem;
    font-family: 'Work Sans';
    font-size: 0.45rem;
    border: solid 0.07rem;
    border-radius: 0.12rem;
    border-color: black;
}