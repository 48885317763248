@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

html{
    font-size: 5vh;
}

body{
    margin:0;
    background-color: #fffaeb;
}
