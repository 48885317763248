.section-main{
    margin: .6rem 0;
}

.section-field-name, .section-field-type{
    width: 100%;
    height: 100%;
}

.section-field-name div, .section-field-type div{
    font-size: .4rem;
    height: 100%;
}

.section-field-name label, .section-field-type label{
    font-size: .35rem;
    height: 100%;
    line-height: .6rem;
}

.section-field-type p{
    font-size: .25rem;
}

.section-field-type svg{
    font-size: .8rem;
}

.section-field-type-menuitem{
    font-size: .4rem !important;
}

.section-field-wrapper{
    height: 2.65rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
    border-top: .05rem solid gainsboro;
    border-bottom: .05rem solid gainsboro;
}

.section-field-wrapper#left{
    border-left: .05rem solid gainsboro;
    border-radius: .5rem 0 0 .5rem;
    padding-right: calc(calc(var(--bs-gutter-x) * .5)/2);
}

.section-field-wrapper#right{
    border-right: .05rem solid gainsboro;
    border-radius: 0 .5rem .5rem 0;
    padding-left: calc(calc(var(--bs-gutter-x) * .5)/2);
}

.section-icon{
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:.4rem;
    cursor: pointer;
}

.section-icon-delete{
    margin-right: 0;
    margin-left: .4rem;
}

.section-icon-wrapper{
    height: 2.65rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-left: calc(calc(var(--bs-gutter-x) * .5)*2);
    padding-right: 0;
}

.section-icon-wrapper#right{
    align-items: flex-start;
    padding-left: 0;
    padding-right: calc(calc(var(--bs-gutter-x) * .5)*2);
}

.section-icon svg{
    height: 100%;
    font-size: .6rem;
}