.common-alert-main{
    padding: 1rem 4rem 0;
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, padding 0.5s ease-in-out;
    opacity: 1;
    height: auto;
}

.common-alert-main * {
    font-size: .35rem !important;
    align-items: center;
    border-radius: .2rem !important;
}

.fade-out {
    opacity: 0;
}

.fade-in {
    opacity: 1;
}