.template-table-main{
    margin: .6rem 0;
}

.template-table-main tbody, .template-table-main thead{
    background-color: #fffaeb;
}

.template-table-button{
    font-size: .6rem;
    margin: .15rem;
    cursor: pointer;
}

.template-table-deleteButton{
    color:#f08080;
}

.template-table-addButton{
    color:#90be6d;
}

.template-table-editButton{
    color:#f9c74f;
}

.template-table-editButton-disabled, .template-table-deleteButton-disabled{
    color:rgba(0, 0, 0, 0.26);
    cursor: not-allowed;
}