.application-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 6em;
    padding: 1em;
    font-size: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px grey;
}

.application-button:hover {
    box-shadow: 0px 2px 3px grey;
    position: relative;
    top: 2px;
    cursor: pointer;
}
